import * as React from "react"
import { graphql, Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ThemeContext from "../context/ThemeContext"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPhoneSquare,
  faSquareEnvelope,
} from "@fortawesome/free-solid-svg-icons"
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"

const industries = [
  {
    title: "Startup",
    href: "/",
  },
  {
    title: "Education",
    href: "/",
  },
  {
    title: "Real Estate",
    href: "/",
  },
  {
    title: "Logistics",
    href: "/",
  },
  {
    title: "Restaurants & Takeaways",
    href: "/",
  },
  {
    title: "Travel & Tourism",
    href: "/",
  },
  {
    title: "GYM",
    href: "/",
  },
  {
    title: "Healthcare",
    href: "/",
  },
  {
    title: "Event Management",
    href: "/",
  },
  {
    title: "Beauty & SPA",
    href: "/",
  },
  {
    title: "Retail",
    href: "/",
  },
  {
    title: "Non-Profit",
    href: "/",
  },
]

const ServicesPage = data => {
  return (
    <ThemeContext.Consumer>
      {theme => (
        <Layout>
          <Seo title="Services of Techimpace Best Digital Agency for Website Designing, Web Development and Software Development" />

          <section
            id=""
            className={
              theme.dark ? "dark dark-5 p-b-0 p-t-0" : "light p-b-0 p-t-0"
            }
          >
            <div className="container-fluid">
              <div
                className="row animated fadeInUp visible"
                data-animate="fadeInUp"
              >
                <div className="col-lg-12 bg-danger p-100">
                  <h3 className="text-light">Browse By Industry / Use Case</h3>

                  <div>
                    {industries.map((post, i) => (
                      <Link
                        to="/"
                        className="btn btn-outline btn-light btn-rounded"
                      >
                        {post.title}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section
            className={
              theme.dark ? "dark dark-5 p-b-0 p-t-0" : "light p-b-0 p-t-0"
            }
          >
            <div className="container pt-5">
              <div className="row">
                <div className="col-xs-12 col-md-6 col-lg-4 col-xl-3">
                  <div
                    className={theme.dark ? "post-item" : "post-item border"}
                  >
                    <div
                      className={
                        theme.dark ? "post-item-wrap dark-3" : "post-item-wrap"
                      }
                    >
                      <div className="post-image">
                        <a href="#">
                          <StaticImage
                            src="../images/services/web-development-concept.png"
                            alt="Website Designing"
                          />
                        </a>
                      </div>
                      <div className="post-item-description">
                        <h2 className={theme.dark ? "text-white" : "text-dark"}>
                          <a href="#">Website Designing</a>
                        </h2>
                        <p
                          className={
                            theme.dark ? "text-gray-300" : "text-gray-500"
                          }
                        >
                          We create beautiful, functional, and user-friendly
                          websites that help you achieve your business goals.
                        </p>
                        <Link
                          to="/services/web-designing"
                          className={
                            theme.dark
                              ? "btn btn-rounded btn-light"
                              : "btn btn-rounded shadow"
                          }
                        >
                          Get Started <i className="icon-chevron-right"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-md-6 col-lg-4 col-xl-3">
                  <div
                    className={theme.dark ? "post-item" : "post-item border"}
                  >
                    <div
                      className={
                        theme.dark ? "post-item-wrap dark-3" : "post-item-wrap"
                      }
                    >
                      <div className="post-image">
                        <a href="#">
                          <StaticImage
                            src="../images/services/web-development.png"
                            alt="Website Development"
                          />
                        </a>
                      </div>
                      <div className="post-item-description">
                        <h2 className={theme.dark ? "text-white" : "text-dark"}>
                          <a href="#">Web Development</a>
                        </h2>
                        <p
                          className={
                            theme.dark ? "text-gray-300" : "text-gray-500"
                          }
                        >
                          We build custom web applications that help businesses
                          grow and succeed.
                        </p>
                        <Link
                          to="/services/web-development"
                          className={
                            theme.dark
                              ? "btn btn-rounded btn-light"
                              : "btn btn-rounded shadow"
                          }
                        >
                          Get Started <i className="icon-chevron-right"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-md-6 col-lg-4 col-xl-3">
                  <div
                    className={theme.dark ? "post-item" : "post-item border"}
                  >
                    <div
                      className={
                        theme.dark ? "post-item-wrap dark-3" : "post-item-wrap"
                      }
                    >
                      <div className="post-image">
                        <a href="#">
                          <StaticImage
                            src="../images/services/digital-marketing-concept.png"
                            alt="Digital Marketing"
                          />
                        </a>
                      </div>
                      <div className="post-item-description">
                        <h2 className={theme.dark ? "text-white" : "text-dark"}>
                          <a href="#">Digital Marketing</a>
                        </h2>
                        <p
                          className={
                            theme.dark ? "text-gray-300" : "text-gray-500"
                          }
                        >
                          We help businesses grow their online presence and
                          reach more customers.
                        </p>
                        <Link
                          to="/services/digital-marketing"
                          className={
                            theme.dark
                              ? "btn btn-rounded btn-light"
                              : "btn btn-rounded shadow"
                          }
                        >
                          Get Started <i className="icon-chevron-right"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-md-6 col-lg-4 col-xl-3">
                  <div
                    className={theme.dark ? "post-item" : "post-item border"}
                  >
                    <div
                      className={
                        theme.dark ? "post-item-wrap dark-3" : "post-item-wrap"
                      }
                    >
                      <div className="post-image">
                        <a href="#">
                          <StaticImage
                            src="../images/services/branding.png"
                            alt="Corporate Branding"
                          />
                        </a>
                      </div>
                      <div className="post-item-description">
                        <h2 className={theme.dark ? "text-white" : "text-dark"}>
                          <a href="/services/corporate-branding">
                            Corporate Branding
                          </a>
                        </h2>
                        <p
                          className={
                            theme.dark ? "text-gray-300" : "text-gray-500"
                          }
                        >
                          We help you create a strong and memorable brand that
                          will help you stand out from the competition.
                        </p>
                        <Link
                          to="/services/corporate-branding"
                          className={
                            theme.dark
                              ? "btn btn-rounded btn-light"
                              : "btn btn-rounded shadow"
                          }
                        >
                          Get Started <i className="icon-chevron-right"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-md-6 col-lg-4 col-xl-3">
                  <div
                    className={theme.dark ? "post-item" : "post-item border"}
                  >
                    <div
                      className={
                        theme.dark ? "post-item-wrap dark-3" : "post-item-wrap"
                      }
                    >
                      <div className="post-image">
                        <a href="#">
                          <StaticImage
                            src="../images/services/android-development.png"
                            alt="Mobile Apps Development"
                          />
                        </a>
                      </div>
                      <div className="post-item-description">
                        <h2 className={theme.dark ? "text-white" : "text-dark"}>
                          <a href="#">Mobile Apps Development</a>
                        </h2>
                        <p
                          className={
                            theme.dark ? "text-gray-300" : "text-gray-500"
                          }
                        >
                          We create custom mobile apps that help businesses
                          reach their target audience and grow their bottom
                          line.
                        </p>
                        <Link
                          to="/services/mobile-apps-development"
                          className={
                            theme.dark
                              ? "btn btn-rounded btn-light"
                              : "btn btn-rounded shadow"
                          }
                        >
                          Get Started <i className="icon-chevron-right"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-md-6 col-lg-4 col-xl-3">
                  <div
                    className={theme.dark ? "post-item" : "post-item border"}
                  >
                    <div
                      className={
                        theme.dark ? "post-item-wrap dark-3" : "post-item-wrap"
                      }
                    >
                      <div className="post-image">
                        <a href="#">
                          <StaticImage
                            src="../images/services/ecommerce-development.png"
                            alt="Ecommerce Development"
                          />
                        </a>
                      </div>
                      <div className="post-item-description">
                        <h2 className={theme.dark ? "text-white" : "text-dark"}>
                          <a href="#">Ecommerce Development</a>
                        </h2>
                        <p
                          className={
                            theme.dark ? "text-gray-300" : "text-gray-500"
                          }
                        >
                          We build custom ecommerce websites & apps that help
                          businesses sell their products and services online.
                        </p>
                        <Link
                          to="/services/ecommerce-develoment"
                          className={
                            theme.dark
                              ? "btn btn-rounded btn-light"
                              : "btn btn-rounded shadow"
                          }
                        >
                          Get Started <i className="icon-chevron-right"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-md-6 col-lg-4 col-xl-3">
                  <div
                    className={theme.dark ? "post-item" : "post-item border"}
                  >
                    <div
                      className={
                        theme.dark ? "post-item-wrap dark-3" : "post-item-wrap"
                      }
                    >
                      <div className="post-image">
                        <a href="#">
                          <StaticImage
                            src="../images/services/cloud-server-concept.png"
                            alt="IT Consultancy"
                          />
                        </a>
                      </div>
                      <div className="post-item-description">
                        <h2 className={theme.dark ? "text-white" : "text-dark"}>
                          <a href="#">IT Consultancy</a>
                        </h2>
                        <p
                          className={
                            theme.dark ? "text-gray-300" : "text-gray-500"
                          }
                        >
                          We help businesses improve their IT infrastructure and
                          operations for better security, sustainability &
                          scalability.
                        </p>
                        <Link
                          to="/services/it-consultancy"
                          className={
                            theme.dark
                              ? "btn btn-rounded btn-light"
                              : "btn btn-rounded shadow"
                          }
                        >
                          Get Started <i className="icon-chevron-right"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-md-6 col-lg-4 col-xl-3">
                  <div
                    className={theme.dark ? "post-item" : "post-item border"}
                  >
                    <div
                      className={
                        theme.dark ? "post-item-wrap dark-3" : "post-item-wrap"
                      }
                    >
                      <div className="post-image">
                        <a href="#">
                          <StaticImage
                            src="../images/services/seo-services.png"
                            alt="SEO"
                          />
                        </a>
                      </div>
                      <div className="post-item-description">
                        <h2 className={theme.dark ? "text-white" : "text-dark"}>
                          <a href="#">SEO</a>
                        </h2>
                        <p
                          className={
                            theme.dark ? "text-gray-300" : "text-gray-500"
                          }
                        >
                          We help you get your website found on Google and other
                          search engines. Increase Visitors! Increase Business!
                        </p>
                        <Link
                          to="/services/seo-search-engine-optimization"
                          className={
                            theme.dark
                              ? "btn btn-rounded btn-light"
                              : "btn btn-rounded shadow"
                          }
                        >
                          Get Started <i className="icon-chevron-right"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-md-6 col-lg-4 col-xl-3">
                  <div
                    className={theme.dark ? "post-item" : "post-item border"}
                  >
                    <div
                      className={
                        theme.dark ? "post-item-wrap dark-3" : "post-item-wrap"
                      }
                    >
                      <div className="post-image">
                        <a href="#">
                          <StaticImage
                            src="../images/services/graphics-designing.png"
                            alt="Graphics Designing"
                          />
                        </a>
                      </div>
                      <div className="post-item-description">
                        <h2 className={theme.dark ? "text-white" : "text-dark"}>
                          <a href="">Graphics Designing</a>
                        </h2>
                        <p
                          className={
                            theme.dark ? "text-gray-300" : "text-gray-500"
                          }
                        >
                          Yes Design Matters!! We create visually stunning
                          graphics that communicate your message effectively.
                        </p>
                        <Link
                          to="/services/graphics-designing"
                          className={
                            theme.dark
                              ? "btn btn-rounded btn-light"
                              : "btn btn-rounded shadow"
                          }
                        >
                          Get Started <i className="icon-chevron-right"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-md-6 col-lg-4 col-xl-3">
                  <div
                    className={theme.dark ? "post-item" : "post-item border"}
                  >
                    <div
                      className={
                        theme.dark ? "post-item-wrap dark-3" : "post-item-wrap"
                      }
                    >
                      <div className="post-image">
                        <a href="#">
                          <StaticImage
                            src="../images/services/ai.png"
                            alt="IT Consultancy"
                          />
                        </a>
                      </div>
                      <div className="post-item-description">
                        <h2 className={theme.dark ? "text-white" : "text-dark"}>
                          <a href="#">AI - Artificial Intelligence</a>
                        </h2>
                        <p
                          className={
                            theme.dark ? "text-gray-300" : "text-gray-500"
                          }
                        >
                          We help businesses build and deploy AI-powered
                          (Artificial Intelligence) solutions that solve
                          real-world problems.
                        </p>
                        <Link
                          to="/services/ai-artificial-intelligence"
                          className={
                            theme.dark
                              ? "btn btn-rounded btn-light"
                              : "btn btn-rounded shadow"
                          }
                        >
                          Get Started <i className="icon-chevron-right"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-md-6 col-lg-4 col-xl-3">
                  <div
                    className={theme.dark ? "post-item" : "post-item border"}
                  >
                    <div
                      className={
                        theme.dark ? "post-item-wrap dark-3" : "post-item-wrap"
                      }
                    >
                      <div className="post-image">
                        <a href="#">
                          <StaticImage
                            src="../images/services/cloud-classroom.png"
                            alt="Cloud Classroom"
                          />
                        </a>
                      </div>
                      <div className="post-item-description">
                        <h2 className={theme.dark ? "text-white" : "text-dark"}>
                          <a href="#">Cloud Classrooms</a>
                        </h2>
                        <p
                          className={
                            theme.dark ? "text-gray-300" : "text-gray-500"
                          }
                        >
                          Cloud-based classroom solutions that make learning
                          more engaging and effective. Save Money, Serve More
                          Students.
                        </p>
                        <Link
                          to="/services/cloud-classrooms"
                          className={
                            theme.dark
                              ? "btn btn-rounded btn-light"
                              : "btn btn-rounded shadow"
                          }
                        >
                          Get Started <i className="icon-chevron-right"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-md-6 col-lg-4 col-xl-3">
                  <div
                    className={theme.dark ? "post-item" : "post-item border"}
                  >
                    <div
                      className={
                        theme.dark ? "post-item-wrap dark-3" : "post-item-wrap"
                      }
                    >
                      <div className="post-image">
                        <a href="#">
                          <StaticImage
                            src="../images/services/games-development.png"
                            alt="IT Consultancy"
                          />
                        </a>
                      </div>
                      <div className="post-item-description">
                        <h2 className={theme.dark ? "text-white" : "text-dark"}>
                          <a href="/services/it-consultancy">
                            Smart Computing Lab
                          </a>
                        </h2>
                        <p
                          className={
                            theme.dark ? "text-gray-300" : "text-gray-500"
                          }
                        >
                          Smart computing lab solutions that empower students to
                          learn and create with Hands-on learning experiences.
                        </p>
                        <Link
                          to="/services/website-designing"
                          className={
                            theme.dark
                              ? "btn btn-rounded btn-light"
                              : "btn btn-rounded shadow"
                          }
                        >
                          Get Started <i className="icon-chevron-right"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Layout>
      )}
    </ThemeContext.Consumer>
  )
}
export default ServicesPage
